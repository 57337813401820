import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import Logo from "../images/RHALogo.jpg";
import RobotoReg from "../fonts/Roboto-Regular.ttf";
import RobotoBold from "../fonts/Roboto-Bold.ttf";
import * as configuration from "../config";
import { getImage } from '../api/ImageApi';
import Html from 'react-pdf-html';

const stylesheet = {
  // clear margins for all <p> tags
  p: {
    margin: 0,
    marginBottom: "8pt",
    fontSize: "8pt",
  },
  em: {
    fontFamily: "Helvetica-Oblique",
    fontStyle: "italic"
  },
  strong: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold"
  },
  "em strong": {
    fontFamily: "Helvetica-BoldOblique",
    fontStyle: "italic",
    fontWeight: "bold"
  },
};

function GeneratePdf(props) {

  const apikey = sessionStorage.getItem('apikey');

  function getDate() {
    const today = new Date();
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    const year = today.getFullYear();
    const date = ("0" + today.getDate()).slice(-2);
    return `${date}/${month}/${year}`;
  }

  function strReplace(str){
    if(str === null){
      return '';
    }
    str = str.replaceAll("&pound;", "£");
    str = str.replaceAll("&euro;", "€");
    return str;
  }

  return (
    <Document>
      <Page style={styles.body} wrap>
        <View style={styles.header} fixed>
          <View style={styles.headerAddress}>
            <Text style={[styles.headerAddressText, { fontWeight: "bold" }]}>RHA Furniture</Text>
            <Text style={styles.headerAddressText}>The Mille, 2nd Floor, 1000 Great West Road, Brentford, TW8 9DW, UK</Text>
          </View>
          <View style={styles.logo}><Image src={Logo} styles={styles.logoImage} /></View>
        </View>
        <View>
          <Text style={{ fontWeight: "bold", fontSize: "14pt", marginBottom: "10pt" }}>{props.title}</Text>
          { props.type === "quote" && <View style={styles.detailsRow}><Text style={styles.detailLabel}>Quotation No.:</Text><Text>{props.projectData.project_number}</Text></View>}
          <View style={styles.detailsRow}><Text style={styles.detailLabel}>Our Ref:</Text><Text>{props.projectData.internal_ref}</Text></View>
          {props.type === "invoice" && <View style={styles.detailsRow}><Text style={styles.detailLabel}>Order No.:</Text><Text>{props.projectData.order_number}</Text></View>}
          <View style={styles.detailsRow}><Text style={styles.detailLabel}>Date:</Text><Text>{getDate()}</Text></View>
          <View style={styles.detailsRow}><Text style={styles.detailLabel}>Revision:</Text><Text>{props.projectData.revision_number}</Text></View>
          <View style={styles.detailsRow}><Text style={{ fontSize: "8pt", paddingTop: "8pt" }}>N.B the order number and reference must be quoted on all correspondence</Text></View>
        </View>
        <View style={styles.divider}></View>
        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: "50%", paddingRight: "10pt" }}>
            <View style={styles.detailsRow}><Text>To: </Text><Text style={{fontWeight: "bold"}}>{props.projectData.quote_to}</Text></View>
            <View style={styles.detailsRow}><Html stylesheet={stylesheet}>{`${props.projectData.quote_contact_details}`}</Html></View>            
          </View>
          <View style={{ width: "50%", paddingLeft: "10pt" }}>
            <View style={styles.detailsRow}><Text style={[styles.detailLabel, { width: "100%" }]}>Delivery to/work to be carried out at:</Text></View>
            <View>
              <Html stylesheet={stylesheet}>{`${props.projectData.delivery_details}`}</Html>
            </View>
            <View style={styles.detailsRow}><Html stylesheet={stylesheet}>{`${strReplace(props.projectData.settlement_terms)}`}</Html></View>
            <View style={styles.detailsRow}><Text style={[styles.detailLabel, { width: "80pt" }]}>Location on site:</Text><Html stylesheet={stylesheet}>{`${props.projectData.location_details ? props.projectData.location_details : ''}`}</Html></View>
          </View>
        </View>
        <View style={styles.divider}></View>
        <View style={styles.detailsRow}><Text>To supply in accordance with rha furniture ltd Terms and Conditions the following:</Text></View>

        <View style={styles.table}>
          <View style={styles.rowHeader}>
            <View style={[styles.cell, { 'width': "6%" }]}><Text>Qty</Text></View>
            <View style={[styles.cell, { 'width': "10%" }]}><Text>Code</Text></View>
            <View style={[styles.cell, { 'width': "30%" }]}><Text>Description</Text></View>
            <View style={[styles.cell, { 'width': "30%" }]}><Text>Design Intent</Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text>Unit Price</Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text>Total</Text></View>
          </View>
          {props.productsGrouped.map((groupedProd, i) => {
            return (
              <>
                <View style={styles.rowFooter} key={i} wrap={false}>
                  <View style={[styles.cell, { 'width': "100%" }]}>
                    <Text>{groupedProd.area}</Text>
                  </View>
                </View>
                {groupedProd.products.map((item, key) => {
                  return (
                    <View style={styles.row} key={key} wrap={false}>
                      <View style={[styles.cell, { 'width': "6%" }]}><Text>{item.quantity}</Text></View>
                      <View style={[styles.cell, { 'width': "10%" }]}><Text>{item.code}</Text></View>
                      <View style={[styles.cell, { 'width': "30%" }]}>
                        <Html stylesheet={stylesheet}>{`${strReplace(item.description)}`}</Html>
                      </View>
                      <View style={[styles.cell, { 'width': "30%", display: 'flex', flexDirection: 'row', flexWrap: "wrap", alignItems: "center" }]}>
                        {item.imageFiles.length && item.imageFiles.map((image, index) => <View key={image} style={{ width: index === 0 ? "100%" : "49%", padding: "2pt", boxSizing: "border-box", height: "auto", display: "block" }}><Image style={{ width: "auto", height: "auto" }} src={`${configuration.baseUrl}/index.php?option=com_customapi&view=imagebuffers&apikey=${apikey}&filename=${image}`} /></View>)}
                      </View>
                      <View style={[styles.cell, { 'width': "12%" }]}><Text>{props.hasCommission ? item.unit_price : item.unit_price_no_commission}</Text></View>
                      <View style={[styles.cell, { 'width': "12%" }]}><Text>{props.hasCommission ? item.total : item.total_no_commission}</Text></View>
                    </View>
                  )
                })}
              </>
            )
          })}
          {props.extras.map((item, key) => {
            let description = item.description.replaceAll("&pound;", "£");
            return (
              <View style={styles.row} key={key} wrap={false}>
                <View style={[styles.cell, { 'width': "6%" }]}><Text>{item.quantity}</Text></View>
                <View style={[styles.cell, { 'width': "10%" }]}><Text>{item.code}</Text></View>
                <View style={[styles.cell, { 'width': "60%" }]}>
                  <Html stylesheet={stylesheet}>{`${description}`}</Html>
                </View>
                <View style={[styles.cell, { 'width': "12%" }]}><Text>{item.unit_price}</Text></View>
                <View style={[styles.cell, { 'width': "12%" }]}><Text>{item.extra_total}</Text></View>
              </View>
            )
          })}
          <View wrap={false}>
          <View style={styles.rowFooter} >
            <View style={[styles.cell, { 'width': "6%" }]}></View>
            <View style={[styles.cell, { 'width': "10%" }]}></View>
            <View style={[styles.cell, { 'width': "60%" }]}><Text>Net Total</Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text>{props.total}</Text></View>
          </View>
          <View style={styles.rowFooter}>
            <View style={[styles.cell, { 'width': "6%" }]}></View>
            <View style={[styles.cell, { 'width': "10%" }]}></View>
            <View style={[styles.cell, { 'width': "60%" }]}><Text>VAT @ 20%</Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text>{props.vat}</Text></View>
          </View>
          <View style={styles.rowFooter}>
            <View style={[styles.cell, { 'width': "6%" }]}></View>
            <View style={[styles.cell, { 'width': "10%" }]}></View>
            <View style={[styles.cell, { 'width': "60%" }]}><Text style={{ fontWeight: "bold" }}>Total Order Value</Text></View>
            <View style={[styles.cell, { 'width': "12%" }]}></View>
            <View style={[styles.cell, { 'width': "12%" }]}><Text style={{ fontWeight: "bold" }}>{props.totalWithVat}</Text></View>
          </View>
          {props.deposit &&
            <View style={styles.rowFooter}>
              <View style={[styles.cell, { 'width': "6%" }]}></View>
              <View style={[styles.cell, { 'width': "10%" }]}></View>
              <View style={[styles.cell, { 'width': "60%" }]}><Text>50% Deposit with Order</Text></View>
              <View style={[styles.cell, { 'width': "12%" }]}></View>
              <View style={[styles.cell, { 'width': "12%" }]}><Text>{props.deposit}</Text></View>
            </View>
          }
          </View>
        </View>
        <View>
          <View><Text style={{paddingTop: "10pt", paddingBottom: "10pt"}}>Quote valid for 10 days only</Text></View>
          <View><Text>Issued by: {props.projectData.quote_issued_by}</Text></View>
          <View style={styles.divider}></View>
          <View><Html stylesheet={stylesheet}>{`${strReplace(props.projectData.bank_details)}`}</Html></View>
          <View style={styles.divider}></View>
          <View><Html stylesheet={stylesheet}>{`${strReplace(props.projectData.terms_conditions)}`}</Html>
          </View>
        </View>

        <View style={styles.footer} fixed>
          <View style={{ width: "80%" }}>
            <Text>Registered Address: The Mille, 2nd Floor, 1000 Great West Road, Brentford, TW8 9DW, UK</Text>
            <Text>Registered in England. Registered number: 4311457</Text>
          </View>
          <View style={{ width: "20%" }}>
            <Text style={{ textAlign: "right" }} render={({ pageNumber, totalPages }) => (
              `${pageNumber} / ${totalPages}`
            )} />
          </View>
        </View>
      </Page>
    </Document>
  )
};


Font.register({
  family: 'Roboto', fonts: [
    { src: RobotoReg },
    { src: RobotoBold, fontWeight: 'bold' },
  ]
});

const styles = StyleSheet.create({
  body: {
    padding: "30pt",
    fontFamily: "Roboto",
    fontSize: "8pt",
    position: "relative",
    paddingBottom: "80pt",
    width: "100%"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "20pt",
    marginBottom: "20pt",
    borderBottomWidth: "1px",
    borderBottomColor: "#999",
    borderBottomStyle: "solid",
  },
  headerAddress: {
    width: "50%"
  },
  headerAddressText: {
    marginBottom: "2pt"
  },
  logo: {
    width: "25%",
  },
  logoImage: {
    display: "block",
    width: "100%",
    height: "auto"
  },
  detailsRow: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "4pt"
  },
  detailLabel: {
    fontWeight: "bold",
    width: "60pt",
  },
  boldText: {
    fontWeight: "bold"
  },
  table: {
    width: "100%",
    marginTop: "10pt",
    borderBottomWidth: "1px",
    borderBottomColor: "#666",
    borderBottomStyle: "solid",
    // borderRightWidth: "1px",
    // borderRightColor: "#999",
    // borderRightStyle: "solid",
    boxSizing: "border-box",
  },
  rowHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderTopWidth: "1px",
    borderTopColor: "#999",
    borderTopStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "#999",
    borderRightStyle: "solid",
    boxSizing: "border-box",
    backgroundColor: "#DDDDDD"
  },
  rowFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderTopWidth: "1px",
    borderTopColor: "#999",
    borderTopStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "#999",
    borderRightStyle: "solid",
    boxSizing: "border-box",
    backgroundColor: "#F6F6F6"
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderTopWidth: "1px",
    borderTopColor: "#999",
    borderTopStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "#999",
    borderRightStyle: "solid",
    boxSizing: "border-box",
  },
  cell: {
    padding: "10pt",
    borderLeftWidth: "1px",
    borderLeftColor: "#999",
    borderLeftStyle: "solid",
    boxSizing: "border-box"
  },
  divider: {
    paddingTop: "10pt",
    marginBottom: "10pt",
    borderBottomWidth: "1px",
    borderBottomColor: "#999",
    borderBottomStyle: "solid",
    boxSizing: "border-box",
  },
  footer: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    left: "30px",
    color: "#000",
    paddingVertical: 20,
    fontSize: "8pt",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    boxSizing: "border-box",
    borderTopWidth: "1px",
    borderTopColor: "#DDD",
    borderTopStyle: "solid",
  }
});

//ReactPDF.render(<Quixote />);

export default GeneratePdf