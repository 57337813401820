import React, { useState, useContext, useEffect, useRef } from 'react'
import * as api from '../../api/ProjectsApi';
import { ProjectsContext } from '../../pages/Projects'
import FormElement from '../FormElement';
import Modal from '../Modal';
import Loader from '../Loader';
import Dropzone from '../Dropzone';
import { getCustomer } from '../../api/CustomersApi';
import Toast from '../Toast';
import { Tab } from '@headlessui/react';
import _ from "lodash";
import ProjectCustomerForm from './ProjectCustomerForm';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function ProjectForm(props) {

    const apikey = sessionStorage.getItem('apikey');
    const userid = sessionStorage.getItem('userid');

    const [projectId, setProjectId] = useState(props.projectId);

    const { refresh, setRefresh, isOpen, setIsOpen } = useContext(ProjectsContext);

    const [isOpen2, setIsOpen2] = useState(false);
    const [projectCustomers, setProjectCustomers] = useState({});
    const [customerId, setCustomerId] = useState();
    const [projectfiles, setProjectfiles] = useState([]);
    const [newProjectfiles, setNewProjectfiles] = useState([]);
    const [editorValues, setEditorValues] = useState([]);

    const [toastTitle, setToastTitle] = useState("");
    const [toastText, setToastText] = useState("");
    const [isOpenToast, setIsOpenToast] = useState(false);
    const [toastType, setToastType] = useState("");

    const [customerFormIsOpen, setCustomerFormIsOpen] = useState(false);
    const [projectCustomerId, setProjectCustomerId] = useState("");

    const [projectData, setProjectData] = useState({
        "project_number": '',
        "project_name": '',
        "project_type": props.projectType,
        "project_description": '',
        "customer": '',
        "customer_id": '',
        "commission": '',
        "files": '',
        "created_by": userid,
        "modified_by": userid,
    });

    const [projectNumbers, setProjectNumbers] = useState([]);

    useEffect(() => {
        api.getProjects(apikey).then((resp) => {
            setProjectNumbers(resp.map((item) => {
                if(item.attributes.id !== projectId){
                    return item.attributes.project_number;
                }
            }));
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }, []);

    useEffect(() => {
        if (!projectId) {
            api.getConfig(apikey).then((resp) => {
                setProjectData({
                    ...projectData,
                    "terms_conditions": resp.terms_conditions,
                    "order_terms": resp.order_terms,
                    "settlement_terms": resp.settlement_terms,
                    "order_settlement_terms": resp.order_settlement_terms,
                    "bank_details": resp.bank_details
                });
            });
        }
    }, []);


    const doToast = (type, title, message, doRefresh) => {
        if (doRefresh) {
            setRefresh(!refresh);
        }
        setToastType(type);
        setToastTitle(title);
        setToastText(message);
        setIsOpenToast(true);
    }

    useEffect(() => {
        api.getProjectsCustomers(apikey).then((resp) => {
            let projectCategoryData = [];
            resp.map((item, index) => {
                if (item.attributes.state === 1) {
                    projectCategoryData.push(
                        {
                            'id': item.attributes.id,
                            'name': item.attributes.name,
                            'code': item.attributes.code,
                        }
                    )
                }
            });
            setProjectCustomers(projectCategoryData);
        });
    }, []);


    useEffect(() => {
        if (props.selectedModal === "edit") {
            api.getProject(apikey, props.projectId).then((resp) => {
                console.log("Project Data: ", resp);
                setProjectData({
                    "id": resp.attributes.id,
                    "state": resp.attributes.state,
                    "ordering": resp.attributes.ordering,
                    "created_by": userid,
                    "modified_by": userid,
                    "project_number": resp.attributes.project_number,
                    "project_name": resp.attributes.project_name,
                    "project_description": resp.attributes.project_description,
                    "customer": resp.attributes.customer,
                    "files": resp.attributes.files,
                    "customer_id": resp.attributes.customer_id,
                    "commission": resp.attributes.commission,
                    "total": resp.attributes.total,
                    "quote_number": resp.attributes.quote_number,
                    "order_number": resp.attributes.order_number,
                    "revision_number": resp.attributes.revision_number,
                    "internal_ref": resp.attributes.internal_ref,
                    "external_ref": resp.attributes.external_ref,
                    "delivery_details": resp.attributes.delivery_details,
                    "settlement_terms": resp.attributes.settlement_terms,
                    "quote_date": resp.attributes.quote_date,
                    "location_details": resp.attributes.location_details,
                    "quote_notes": resp.attributes.quote_notes,
                    "quote_issued_by": resp.attributes.quote_issued_by,
                    "terms_conditions": resp.attributes.terms_conditions,
                    "order_terms": resp.attributes.order_terms,
                    "bank_details": resp.attributes.bank_details,
                    "quote_contact_details": resp.attributes.quote_contact_details,
                    "invoice_contact_details": resp.attributes.invoice_contact_details,
                    "order_contact_details": resp.attributes.order_contact_details,
                    "quote_to": resp.attributes.quote_to,
                    "invoice_to": resp.attributes.invoice_to,
                    "order_to": resp.attributes.order_to,
                    "order_settlement_terms": resp.attributes.order_settlement_terms,
                    "order_delivery_details": resp.attributes.order_delivery_details,
                    "invoice_issued_by": resp.attributes.invoice_issued_by,
                    "order_issued_by": resp.attributes.order_issued_by,
                    "invoice_notes": resp.attributes.invoice_notes,
                    "order_notes": resp.attributes.order_notes,
                    "invoice_date": resp.attributes.invoice_date,
                    "order_date": resp.attributes.order_date
                });
                setProjectfiles(resp.attributes.files.split(","));
            });
        }
    }, [props.selectedModal]);


    const onSubmitHandler = (e, submitType) => {
        e.preventDefault();
        if (projectNumbers.includes(projectData.project_number)) {
            doToast("error", "Error", "Project Number must be unique and not empty");
            return;
        }
        if (props.selectedModal === "edit") {
            api.editProject(apikey, { ...projectData, ...editorValues }, projectId).then((resp) => {
                doToast("success", "Success", "Entry saved", true);
                if (submitType === "save_and_close") {
                    setIsOpen(false);
                }
            }).catch((resp) => {
                doToast("error", "Error", resp.message);
            });
        } else {
            api.addProject(apikey, { ...projectData, ...editorValues }).then((resp) => {
                doToast("success", "Success", "Entry saved", true);
                props.setSelectedModal("edit");
                setProjectId(resp.attributes.id);
                if (submitType === "save_and_close") {
                    setIsOpen(false);
                }
            }).catch((resp) => {
                doToast("error", "Error", resp.message);
            });
        }
    }

    const [imagesArr, setImagesArr] = useState([]);

    useEffect(() => {
        setNewProjectfiles(imagesArr);
    }, [imagesArr])

    useEffect(() => {
        let newProds = projectfiles.concat(newProjectfiles);

        var filtered = newProds.filter(function (el) {
            return el != "";
        });

        setProjectData((prevState) => {
            return {
                ...prevState,
                files: filtered.join()
            }
        });
    }, [newProjectfiles])


    const fileUploadHandler = (data) => {
        data.map((item) => {
            setImagesArr((imagesArr) => { return [...imagesArr, item.name.replace(/[^A-Za-z0-9.-]+/g, "")] });
        })
        api.addProjectImage(apikey, data).then((resp) => {
            doToast("success", "Success", "Image uploaded");
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });;
    }

    const fileDeleteHandler = (imagesArr, filename) => {
        setProjectfiles(imagesArr);
        setNewProjectfiles([]);
        api.deleteProjectImage(apikey, filename).then((resp) => {
            setProjectData((prevState) => {
                return {
                    ...prevState,
                    files: imagesArr.join()
                }
            });
            doToast("success", "Success", "Image deleted");
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }

    const fileRemoveHandler = (imagesArr) => {
        setProjectData((prevState) => {
            return {
                ...prevState,
                files: imagesArr.join()
            }
        });
    }

    const fileReorderHandler = (imagesArr) => {
        console.log(imagesArr)
        setProjectData((prevState) => {
            return {
                ...prevState,
                files: imagesArr.join()
            }
        });
    }

    const moveToOrdersHandler = () => {
        api.editProject(apikey, { ...projectData, ...editorValues, project_type: "order", order_number: projectData.orderNumber ? projectData.order_number : projectData.project_number+"_ON" }, projectId).then((resp) => {
            doToast("success", "Success", "Entry moved", true);
            setIsOpen(false);
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }

    const moveToProjectsHandler = () => {
        api.editProject(apikey, { ...projectData, ...editorValues, project_type: "" }, projectId).then((resp) => {
            doToast("success", "Success", "Entry moved", true);
            setIsOpen(false);
        }).catch((resp) => {
            doToast("error", "Error", resp.message);
        });
    }

    const onChangeCustomerHandler = (customerId) => {
        if (customerId) {
            getCustomer(apikey, customerId).then((resp) => {

                let contactDetails = [
                    resp.attributes.billing_addressline1,
                    resp.attributes.billing_addressline2,
                    resp.attributes.billing_addressline3,
                    resp.attributes.billing_addressline4,
                    resp.attributes.billing_locality,
                    resp.attributes.billing_region,
                    resp.attributes.billing_postcode,
                    resp.attributes.billing_country,
                ]

                contactDetails = _.compact(contactDetails);

                let contactStr = "<p>";
                contactDetails.map((item) => {
                    contactStr += item + ",<br/>"
                });
                contactStr = contactStr.slice(0, -6) + "</p>";
                contactStr += "<p><strong>FAO:</strong><br/>" + resp.attributes.contactname.replace(",", "<br/>") + "</p>";
                contactStr += "<p><strong>Email:</strong><br/>" + resp.attributes.email.replace(",", "<br/>") + "</p>";

                let deliveryDetails = [
                    resp.attributes.shipping_addressline1,
                    resp.attributes.shipping_addressline2,
                    resp.attributes.shipping_addressline3,
                    resp.attributes.shipping_addressline4,
                    resp.attributes.shipping_locality,
                    resp.attributes.shipping_region,
                    resp.attributes.shipping_postcode,
                    resp.attributes.shipping_country,
                ]

                deliveryDetails = _.compact(deliveryDetails);

                let deliveryDetailsrStr = "<p>";
                deliveryDetails.map((item) => {
                    deliveryDetailsrStr += item + ",<br/>"
                });
                deliveryDetailsrStr = deliveryDetailsrStr.slice(0, -6) + "</p>";

                setProjectData((prevState) => {
                    return {
                        ...prevState,
                        "customer": resp.attributes.name,
                        "customer_id": customerId,
                        "quote_contact_details": contactStr,
                        "invoice_contact_details": contactStr,
                        "delivery_details": deliveryDetailsrStr,
                        "quote_to": resp.attributes.name,
                        "invoice_to": resp.attributes.name
                    }
                })
            });
        } else {
            setProjectData((prevState) => {
                return {
                    ...prevState,
                    "customer_id": "",
                    "quote_contact_details": ""
                }
            })
        }
    }

    const onCustomerFormClick = (e) => {
        e.preventDefault();
        setCustomerFormIsOpen(true);
    }

    const FormElements = [
        ...props.projectType === 'order' ? [{ elementLabel: "Order Number", elementName: "order_number", elementType: "text", className: "w-[49%]" }] : [],
        { elementLabel: "Quote Number*", elementName: "project_number", elementType: "text", className: "w-[49%]", required: "true" },
        { elementLabel: "Project Name*", elementName: "project_name", elementType: "text", className: "w-[49%]", required: "true" },
        { elementLabel: "Revision Number", elementName: "revision_number", elementType: "text", className: "w-[49%]" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Internal Reference", elementName: "internal_ref", elementType: "text", className: "w-[49%]" },
        { elementLabel: "External Reference", elementName: "external_ref", elementType: "text", className: "w-[49%]" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Customer", elementName: "customer", elementType: "select", controlDependentId: "customer_id", className: "w-[49%]", elementOptions: projectCustomers },
        { elementLabel: "Customer ID", elementValue: customerId, elementName: "customer_id", elementType: "text", className: "w-[49%] hidden" },
        { elementLabel: "Commission (%)", elementValue: customerId, elementName: "commission", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Add Customer", elementName: "add_customer", elementType: "button", className: "w-[49%]", elementAction: onCustomerFormClick },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Description", elementName: "project_description", elementType: "textarea", className: "w-full" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "files", elementName: "files", elementType: "files", className: "w-full" },
    ]

    const FormElementsQuote = [
        // { elementLabel: "Quote Number", elementName: "quote_number", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Quote to Name", elementName: "quote_to", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Quote Issued By", elementName: "quote_issued_by", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Quote Date", elementName: "quote_date", elementType: "date", className: "w-[49%]" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Quote Contact Details", elementName: "quote_contact_details", elementType: "textarea", className: "w-full" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Quote Notes", elementName: "quote_notes", elementType: "textarea", className: "w-full" },
    ]

    const FormElementsInvoice = [
        { elementLabel: "Invoice to Name", elementName: "invoice_to", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Invoice Issued By", elementName: "invoice_issued_by", elementType: "text", className: "w-[49%]" },
        { elementLabel: "Invoice Date", elementName: "invoice_date", elementType: "date", className: "w-[49%]" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Invoice Contact Details", elementName: "invoice_contact_details", elementType: "textarea", className: "w-full" },
        { elementType: "element", className: "w-full mb-8 pt-4 border-b border-gray-200" },
        { elementLabel: "Invoice Notes", elementName: "invoice_notes", elementType: "textarea", className: "w-full" },
    ]

    const FormElementsTerms = [
        { elementLabel: "Settlement Terms", elementName: "settlement_terms", elementType: "textarea", className: "w-full" },
        { elementLabel: "Order Settlement Terms", elementName: "order_settlement_terms", elementType: "textarea", className: "w-full" },
        { elementLabel: "Terms and Conditions", elementName: "terms_conditions", elementType: "textarea", className: "w-full" },
        { elementLabel: "Order Terms Summary", elementName: "order_terms", elementType: "textarea", className: "w-full" },
        { elementLabel: "Bank Details", elementName: "bank_details", elementType: "textarea", className: "w-full" },
    ]

    const FormElementsNotes = [
        { elementLabel: "Delivery Details", elementName: "delivery_details", elementType: "textarea", className: "w-full" },
        { elementLabel: "Location Details", elementName: "location_details", elementType: "textarea", className: "w-full" },
    ]



    return (
        <div className="w-full">
            <div className="flex items-center justify-between w-full">
                <button className="btn flex items-center" onClick={(e) => {
                    onSubmitHandler(e);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>

                    <span className='ml-2'>Save</span>
                </button>
                <button className={`btn flex items-center ml-2 ${props.selectedModal === "add" ? "mr-auto" : ""}`} onClick={(e) => {
                    onSubmitHandler(e, "save_and_close");
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>

                    <span className='ml-2'>Save and Close</span>
                </button>
                {props.selectedModal !== "add" && <button className="btn flex items-center ml-2" onClick={(e) => {
                    e.preventDefault();
                    props.onDuplicateHandler(projectId);
                    setIsOpen(false);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>


                    <span className='ml-2'>Duplicate</span>
                </button>}
                {props.projectType !== "order" && props.selectedModal !== "add" && <button className="btn flex items-center ml-2 mr-auto" onClick={(e) => {
                    e.preventDefault();
                    moveToOrdersHandler();
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                    </svg>



                    <span className='ml-2'>Move to Orders</span>
                </button>}
                {props.projectType === "order" && props.selectedModal !== "add" && <button className="btn flex items-center ml-2 mr-auto" onClick={(e) => {
                    e.preventDefault();
                    moveToProjectsHandler();
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                    </svg>



                    <span className='ml-2'>Move to Quotes</span>
                </button>}
                {props.selectedModal !== "add" && <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={(e) => {
                    e.preventDefault();
                    setIsOpen2(true);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>

                    <span className='ml-2'>Delete</span>
                </button>}
            </div>
            <div className='w-full mb-6 pt-6 border-b border-gray-200'></div>
            <Tab.Group>
                <Tab.List className="flex space-x-1 bg-slate-100 p-1 mb-2">

                    <Tab className={({ selected }) =>
                        classNames(
                            'w-full shadow bg-slate-50 rounded-lg font-medium text-left leading-5 text-gray-500 py-4 px-6',

                            selected
                                ? 'bg-white text-gray-700'
                                : 'hover:bg-white/[0.8]'
                        )
                    }><span className='text-sm'>{props.projectType === "order" ? 'Order': 'Quote'} Details</span>
                    </Tab>
                    {props.projectType !== 'order' &&
                        <Tab className={({ selected }) =>
                            classNames(
                                'w-full shadow bg-slate-50 rounded-lg font-medium text-left leading-5 text-gray-500 py-4 px-6',

                                selected
                                    ? 'bg-white text-gray-700'
                                    : 'hover:bg-white/[0.8]'
                            )
                        }><span className='text-sm'>Contact Details</span>
                        </Tab>
                    }
                    {props.projectType === 'order' &&
                        <Tab className={({ selected }) =>
                            classNames(
                                'w-full shadow bg-slate-50 rounded-lg font-medium text-left leading-5 text-gray-500 py-4 px-6',

                                selected
                                    ? 'bg-white text-gray-700'
                                    : 'hover:bg-white/[0.8]'
                            )
                        }><span className='text-sm'>Invoice Details</span>
                        </Tab>
                    }
                    <Tab className={({ selected }) =>
                        classNames(
                            'w-full shadow bg-slate-50 rounded-lg font-medium text-left leading-5 text-gray-500 py-4 px-6',

                            selected
                                ? 'bg-white text-gray-700'
                                : 'hover:bg-white/[0.8]'
                        )
                    }><span className='text-sm'>Delivery Details</span>
                    </Tab>
                    <Tab className={({ selected }) =>
                        classNames(
                            'w-full shadow bg-slate-50 rounded-lg font-medium text-left leading-5 text-gray-500 py-4 px-6',

                            selected
                                ? 'bg-white text-gray-700'
                                : 'hover:bg-white/[0.8]'
                        )
                    }><span className='text-sm'>Terms</span>
                    </Tab>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel unmount={false}>
                        <div className='flex pb-6 pt-6 w-full justify-between items-center'>
                            <div>
                                <h2 className='text-slate-700 font-bold text-xl'>{props.projectType === "order" ? 'Order': 'Project'} Details</h2>
                            </div>
                        </div>
                        <form onSubmit={onSubmitHandler} className="flex w-full flex-wrap justify-between">
                            {!projectData && <Loader />}
                            {projectData && FormElements.map((item, index) => (
                                <div key={index} className={item.className ? item.className : 'w-full'}>
                                    <FormElement
                                        ref={item.elementRef}
                                        elementLabel={item.elementLabel}
                                        elementName={item.elementName}
                                        elementType={item.elementType}
                                        required={item.required}
                                        elementValue={projectData[item.elementName]}
                                        elementOptions={projectCustomers}
                                        controlDependentId={item.controlDependentId}
                                        fileDeleteHandler={fileDeleteHandler}
                                        fileRemoveHandler={fileRemoveHandler}
                                        fileReorderHandler={fileReorderHandler}
                                        elementAction={item.elementAction}
                                        editorValue={editorValues[item.elementName]}
                                        onEditorChange={(newValue, editor) => {
                                            setEditorValues((prevState) => {
                                                return {
                                                    ...prevState, [editor.id]: newValue
                                                }
                                            });
                                        }}
                                        onBlurHandler={(e)=>{
                                            if(e.target.required && e.target.value === ""){
                                                e.target.classList.add("bg-red-50");    
                                            }
                                        }}
                                        onChangeHandler={(e) => {
                                            if (e.target.id === "customer") {
                                                onChangeCustomerHandler(e.target.options[e.target.selectedIndex].dataset.id);
                                            }
                                            // if (e.target.id === "shipping_date") {
                                            //     console.log(e.target.value);
                                            // }
                                            setProjectData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    [item.elementName]: e.target.value
                                                }
                                            });

                                            if (e.target.id === "commission") {
                                                setProjectData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        total: "Pending Save"
                                                    }
                                                });
                                            }

                                            if(e.target.required && e.target.value === ""){
                                                e.target.classList.add("bg-red-50");    
                                            }else{
                                                e.target.classList.remove("bg-red-50");
                                            }
                                        }} />
                                </div>
                            ))}
                            <Dropzone fileUploadHandler={fileUploadHandler} />
                        </form>
                    </Tab.Panel>
                    {props.projectType !== 'order' &&
                        <Tab.Panel unmount={false}>
                            <div className='flex pb-6 pt-6 w-full justify-between items-center'>
                                <div>
                                    <h2 className='text-slate-700 font-bold text-xl'>Quote Details</h2>
                                </div>
                            </div>
                            <div className="flex w-full flex-wrap justify-between">
                                {projectData && FormElementsQuote.map((item, index) => (
                                    <div key={index} className={item.className ? item.className : 'w-full'}>
                                        <FormElement
                                            ref={item.elementRef}
                                            elementLabel={item.elementLabel}
                                            elementName={item.elementName}
                                            elementType={item.elementType}
                                            required={item.required}
                                            elementValue={projectData[item.elementName]}
                                            elementOptions={projectCustomers}
                                            controlDependentId={item.controlDependentId}
                                            fileDeleteHandler={fileDeleteHandler}
                                            fileRemoveHandler={fileRemoveHandler}
                                            fileReorderHandler={fileReorderHandler}
                                            elementAction={item.elementAction}
                                            editorValue={editorValues[item.elementName]}
                                            onEditorChange={(newValue, editor) => {
                                                setEditorValues((prevState) => {
                                                    return {
                                                        ...prevState, [editor.id]: newValue
                                                    }
                                                });
                                            }}
                                            onBlurHandler={(e)=>{
                                                if(e.target.required && e.target.value === ""){
                                                    e.target.classList.add("bg-red-50");    
                                                }
                                            }}
                                            onChangeHandler={(e) => {
                                                setProjectData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        [item.elementName]: e.target.value
                                                    }
                                                })
                                                if(e.target.required && e.target.value === ""){
                                                    e.target.classList.add("bg-red-50");    
                                                }else{
                                                    e.target.classList.remove("bg-red-50");
                                                }
                                            }} />
                                    </div>
                                ))}
                            </div>
                        </Tab.Panel>
                    }
                    {props.projectType === 'order' &&
                        <Tab.Panel unmount={false}>
                            <div className='flex pb-6 pt-6 w-full justify-between items-center'>
                                <div>
                                    <h2 className='text-slate-700 font-bold text-xl'>Invoice Details</h2>
                                </div>
                            </div>
                            <div className="flex w-full flex-wrap justify-between">
                                {projectData && FormElementsInvoice.map((item, index) => (
                                    <div key={index} className={item.className ? item.className : 'w-full'}>
                                        <FormElement
                                            ref={item.elementRef}
                                            elementLabel={item.elementLabel}
                                            elementName={item.elementName}
                                            elementType={item.elementType}
                                            required={item.required}
                                            elementValue={projectData[item.elementName]}
                                            elementOptions={projectCustomers}
                                            controlDependentId={item.controlDependentId}
                                            fileDeleteHandler={fileDeleteHandler}
                                            fileRemoveHandler={fileRemoveHandler}
                                            fileReorderHandler={fileReorderHandler}
                                            elementAction={item.elementAction}
                                            editorValue={editorValues[item.elementName]}
                                            onEditorChange={(newValue, editor) => {
                                                setEditorValues((prevState) => {
                                                    return {
                                                        ...prevState, [editor.id]: newValue
                                                    }
                                                });
                                            }}
                                            onBlurHandler={(e)=>{
                                                if(e.target.required && e.target.value === ""){
                                                    e.target.classList.add("bg-red-50");    
                                                }
                                            }}
                                            onChangeHandler={(e) => {
                                                setProjectData((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        [item.elementName]: e.target.value
                                                    }
                                                })
                                                if(e.target.required && e.target.value === ""){
                                                    e.target.classList.add("bg-red-50");    
                                                }else{
                                                    e.target.classList.remove("bg-red-50");
                                                }
                                            }} />
                                    </div>
                                ))}
                            </div>
                        </Tab.Panel>
                    }
                    <Tab.Panel unmount={false}>
                        <div className='flex pb-6 pt-6 w-full justify-between items-center'>
                            <div>
                                <h2 className='text-slate-700 font-bold text-xl'>Delivery Details & Notes</h2>
                            </div>
                        </div>
                        <div className="flex w-full flex-wrap justify-between min-h-[800px]">
                            {projectData && FormElementsNotes.map((item, index) => (
                                <div key={index} className={item.className ? item.className : 'w-full'}>
                                    <FormElement
                                        ref={item.elementRef}
                                        elementLabel={item.elementLabel}
                                        elementName={item.elementName}
                                        elementType={item.elementType}
                                        required={item.required}
                                        elementValue={projectData[item.elementName]}
                                        elementOptions={projectCustomers}
                                        controlDependentId={item.controlDependentId}
                                        fileDeleteHandler={fileDeleteHandler}
                                        fileRemoveHandler={fileRemoveHandler}
                                        fileReorderHandler={fileReorderHandler}
                                        elementAction={item.elementAction}
                                        editorValue={editorValues[item.elementName]}
                                        onEditorChange={(newValue, editor) => {
                                            setEditorValues((prevState) => {
                                                return {
                                                    ...prevState, [editor.id]: newValue
                                                }
                                            });
                                        }}
                                        onBlurHandler={(e)=>{
                                                if(e.target.required && e.target.value === ""){
                                                    e.target.classList.add("bg-red-50");    
                                                }
                                            }}
                                        onChangeHandler={(e) => {
                                            setProjectData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    [item.elementName]: e.target.value
                                                }
                                            })
                                            if(e.target.required && e.target.value === ""){
                                                e.target.classList.add("bg-red-50");    
                                            }else{
                                                e.target.classList.remove("bg-red-50");
                                            }
                                        }} />
                                </div>
                            ))}
                        </div>
                    </Tab.Panel>
                    <Tab.Panel unmount={false}>
                        <div className='flex pb-6 pt-6 w-full justify-between items-center'>
                            <div>
                                <h2 className='text-slate-700 font-bold text-xl'>Terms</h2>
                            </div>
                        </div>
                        <div className="flex w-full flex-wrap justify-between min-h-[800px]">
                            {projectData && FormElementsTerms.map((item, index) => (
                                <div key={index} className={item.className ? item.className : 'w-full'}>
                                    <FormElement
                                        ref={item.elementRef}
                                        elementLabel={item.elementLabel}
                                        elementName={item.elementName}
                                        elementType={item.elementType}
                                        required={item.required}
                                        elementValue={projectData[item.elementName]}
                                        elementOptions={projectCustomers}
                                        controlDependentId={item.controlDependentId}
                                        fileDeleteHandler={fileDeleteHandler}
                                        fileRemoveHandler={fileRemoveHandler}
                                        fileReorderHandler={fileReorderHandler}
                                        elementAction={item.elementAction}
                                        editorValue={editorValues[item.elementName]}
                                        onEditorChange={(newValue, editor) => {
                                            setEditorValues((prevState) => {
                                                return {
                                                    ...prevState, [editor.id]: newValue
                                                }
                                            });
                                        }}
                                        onBlurHandler={(e)=>{
                                            if(e.target.required && e.target.value === ""){
                                                e.target.classList.add("bg-red-50");    
                                            }
                                        }}
                                        onChangeHandler={(e) => {
                                            setProjectData((prevState) => {
                                                return {
                                                    ...prevState,
                                                    [item.elementName]: e.target.value
                                                }
                                            })
                                            if(e.target.required && e.target.value === ""){
                                                e.target.classList.add("bg-red-50");    
                                            }else{
                                                e.target.classList.remove("bg-red-50");
                                            }
                                        }} />
                                </div>
                            ))}
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
            <Modal isOpen={customerFormIsOpen} setIsOpen={setCustomerFormIsOpen} setRefresh={setRefresh} refresh={refresh} dialogTitle={'Add Customer'} modalSize={"max-w-3xl"}>
                <ProjectCustomerForm projectCustomers={projectCustomers} setProjectCustomers={setProjectCustomers} onChangeCustomerHandler={onChangeCustomerHandler} setIsOpen={setCustomerFormIsOpen} projectData={projectData} setItemData={setProjectData} />
            </Modal>
            <Modal isOpen={isOpen2} setIsOpen={setIsOpen2} dialogTitle={"Confirm"} modalSize={"max-w-md"}>
                <div className='mb-6'>
                    <p>Are you sure that you want to delete this item?</p>
                </div>
                <div className="flex items-center justify-between">
                    <button className="btn" onClick={() => { setIsOpen2(false) }}>Cancel</button>
                    <button className="btn bg-red-200 hover:bg-red-300 flex items-center" onClick={() => {
                        props.onDeleteHandler(projectId)
                        setIsOpen(false);
                        setIsOpen2(false);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>

                        <span className='ml-2'>Yes Delete</span>
                    </button>
                </div>
            </Modal>
            <Toast toastType={toastType} isOpen={isOpenToast} setIsOpen={setIsOpenToast} toastTitle={toastTitle} toastText={toastText} />
        </div>
    )
}

export default ProjectForm